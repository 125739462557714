import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "open-sorcerers",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#open-sorcerers",
        "aria-label": "open sorcerers permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Open Sorcerers`}</h1>
    <h2 {...{
      "id": "functional-programming-×-javascript-×-open-source",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#functional-programming-%C3%97-javascript-%C3%97-open-source",
        "aria-label": "functional programming × javascript × open source permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Functional Programming `}{`×`}{` JavaScript `}{`×`}{` Open-Source`}</h2>
    <p>{`Our goal is to create a community dedicated to highlighting effective usage of the Functional Programming (FP) paradigm in JavaScript. We hope to provide tools, resources and training towards this goal.`}</p>
    <h3 {...{
      "id": "learn-about-the-fp-paradigm",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#learn-about-the-fp-paradigm",
        "aria-label": "learn about the fp paradigm permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Learn about the FP Paradigm`}</h3>
    <p>{`We believe that following the `}<a parentName="p" {...{
        "href": "/series/fp"
      }}>{`core tenets`}</a>{` of FP leads to cleaner, simpler and easier-to-reason-about code. Check out our `}<a parentName="p" {...{
        "href": "/learn"
      }}>{`Learning`}</a>{` page for the core tenets and additional resources.`}</p>
    <h4 {...{
      "id": "create-or-contribute-to-open-source-javascript-modules",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#create-or-contribute-to-open-source-javascript-modules",
        "aria-label": "create or contribute to open source javascript modules permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Create or Contribute to Open-Source JavaScript modules`}</h4>
    <p>{`If you want to get started writing code, check us out on `}<a parentName="p" {...{
        "href": "https://github.com/open-sorcerers"
      }}>{`Github`}</a>{`.`}</p>
    <p>{`This site is built on `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org"
      }}>{`Gatsby`}</a>{` using the `}<a parentName="p" {...{
        "href": "https://github.com/open-sorcerers/foresight-gatsby-starter"
      }}>{`foresight-gatsby-starter`}</a>{`.`}</p>
    <h5 {...{
      "id": "meet-cool-people-learn-about-software-and-have-fun",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#meet-cool-people-learn-about-software-and-have-fun",
        "aria-label": "meet cool people learn about software and have fun permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Meet cool people, learn about software and have fun`}</h5>
    <p>{`Please reach out to us on `}<a parentName="p" {...{
        "href": "https://open-sorcerers.slack.com"
      }}>{`Slack`}</a>{` or `}<a parentName="p" {...{
        "href": "https://twitter.com/osorcerers"
      }}>{`Twitter`}</a>{` or meet us in person at a `}<a parentName="p" {...{
        "href": "https://meetup.com/open-sorcerers"
      }}>{`Meetup`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      